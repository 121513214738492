import React, {useState, useEffect}from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, HStack, Stack, Text } from  '@arcault-ui/shared-components';
import NextStep from './EstateNextStep';
import Progress from './EstateProgress';
import ProposalInbox from './ProposalInbox'
import DocumentsViewer from './DocumentsViewer';

import './EstateDashboard.css';
import EstateValueCard from './EstateValue';
import TeamViewer from './TeamViewer';

const EstateDashboard = ({action}) => {
    
    const [suggestions, setSuggestions] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        // const response = await axios.get('your-service-endpoint');
        setSuggestions([
            {
      title: 'Create your estate plan in minutes ',
      description: 'Guided by our AI assistant and simple questionaire you can get you estate plan completed in as little as 20 minutes. As part of your membership to this service you able to make updates annually at no cost.',
      action: {
        label: 'Get Started',  
        onClick: () => action('estate.wizard'),
      },
    },
    {
      title: 'Connect with a local professional',
      description: "DIY approach not for you? We can connect you to local attorneys in your area. You will recieve multiple bids and be able to select the estate planner that best fits your needs.",
      action: {
        label: 'Get Started',
        onClick: () => navigate('/estate/rfp'),
      },
    },
    {
      title: 'Have an existing estate plan?',
      description: "Upload the documents by simply dropping files or imgages into your Digital Vault. We'll look everything over for you and can make suggestions for improvements. An older plan provides a good starting point and can help getting everything up to date",
      action: {
        onClick: () => console.log('Profile Update Clicked'),
      },
    }]); // Adjust according to your API response
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    };

    fetchSuggestions();
  }, [action, navigate]);


  return (
    <Card style={{ width: 1000 }} className="card-dashboard">
      <HStack className="estate-planning-content">
        <NextStep />
        <Progress progress={50}/>
        <ProposalInbox />
      </HStack>
      <Stack variant="start">
        <div className="your-vault">
          <Text variant="h4">Your Vault</Text>
        </div>
        <HStack variant="stretch">
          <EstateValueCard navigate={navigate} />
          <TeamViewer />
          <DocumentsViewer />
        </HStack>
      </Stack>
    </Card>
  );
}

export default EstateDashboard;
