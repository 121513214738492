import React, { useState, useMemo } from 'react';
import { ChakraProvider, IconButton, Table, Tbody as TableBody, Td as TableCell, Thead as TableHead, Tr as TableRow } from '@chakra-ui/react';

import { Icon, SearchBar } from '@arcault-ui/shared-components';
import './AssetsTable.css';

const Skeleton = ({className, children}) => (
  <div className={`skeleton ${className}`}>{children}</div> 
)

const AssetsTable = ({ isLoading, columns, rows, actions, displayValue }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [searchTerm, setSearchTerm] = useState('');

  const handleSort = (columnId) => {
    let direction = 'asc';
    if (sortConfig.key === columnId && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: columnId, direction });
  };
  const filteredRows = useMemo(() => {
    return rows.filter((row) =>
      columns.some((column) =>
        row[column.id]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [rows, columns, searchTerm]);

  const sortedRows = useMemo(() => {
    if (sortConfig.key) {
      return [...filteredRows].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredRows;
  }, [filteredRows, sortConfig]);

  return (
    <>
      <SearchBar 
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Table className="arcault-info-assets-table">
        <TableHead>
          <TableRow className="arcault-table-head">
            {columns.map((column) => (
              <th 
                key={column.id}
                align={column.align || 'left'}
                onClick={() => handleSort(column.id)}
                style={{ cursor: 'pointer' }}
              >
                <div className="table-head-label">
                  {column.label}
                  {sortConfig.key === column.id ? (
                  sortConfig.direction === 'asc' ? (
                    <Icon name="keyboard_arrow_up" />
                  ) : (
                    <Icon name="keyboard_arrow_down" />
                  )) : null}
                </div>
              </th>
            ))}
            {actions && <th align="right">Actions</th>}
          </TableRow>
        </TableHead>
        {!isLoading && <TableBody className="arcault-table-body">
          {sortedRows.map((row) => (
            <TableRow key={row.id || row.sortKey}>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align || 'left'}>
                  {displayValue ? displayValue(row[column.id], column) : row[column.id]}
                </TableCell>
              ))}
              {actions && (
                <TableCell align="right">
                  {actions.filter(a => a.condition ? a.condition(row) : true).map((action, index) => (
                    <IconButton
                      key={index}
                      className={action.iconClassName}
                      aria-label={action.label}
                      onClick={() => action.onClick(row)}
                    >
                      <Icon name={action.iconName} />
                    </IconButton>
                  ))}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>}
      </Table>
      {isLoading && (
        <ChakraProvider>
          <Skeleton className="skeleton-row">&nbsp;</Skeleton>
          <Skeleton className="skeleton-row">&nbsp;</Skeleton>
          <Skeleton className="skeleton-row">&nbsp;</Skeleton>
          <Skeleton className="skeleton-row">&nbsp;</Skeleton>
        </ChakraProvider>
      )}
    </>
  );
};

export default AssetsTable;
    /*
    <Table className="arcault-info-assets-table" aria-label="inventory table">
      <TableHead>
        <TableRow className="arcault-table-head">
          {fields.map((field) => (
            field.column &&
            <th key={field.id}>{field.label}</th>
          ))}
          <th>Actions</th>
        </TableRow>
      </TableHead>
      <TableBody className="arcault-table-body">
        {rows.map((row) => (
          <TableRow key={row.id}>
            {fields.map((field) => (
              field.column &&
              <TableCell key={field.id}>{displayValue(row[field.id], field.column)}</TableCell>
            ))}
            <TableCell>
              <IconButton aria-label="edit" onClick={() => handleOpen(row)}><Icon name="edit" /></IconButton>
              <IconButton aria-label="delete" onClick={() => handleDelete(row)}><Icon name="delete" /></IconButton>
              <IconButton aria-label="secret" onClick={() => handleOpenSecret(row)}><Icon name="key" /></IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>*/
