import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { ChakraProvider, Stack, Skeleton, IconButton, Tabs, Tab, TabList } from '@chakra-ui/react';
import { Avatar, Icon, SearchBar, Card, Button, Tag } from '@arcault-ui/shared-components';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddContactDialog from './ContactDialog';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
import './contacts.css';
import AssetsTable from '../common/assetsTable/AssetsTable';


export default function Contacts() {
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [editableContact, setEditableContact] = useState(null);
  // const [newContact, setNewContact] = useState({ firstName: '', lastName: '', email: '', phone: '', type: '' });
  // const [selectedContact, setSelectedContact] = useState({ firstName: '', lastName: '', email: '', phone: '', type: '' });
  const axiosWithCredentials = useAxiosWithCredentials();


  useEffect(() => {
    loadData();
  }, []);


  const columns = [
    { id: 'name', label: 'Name' },
    { id: 'phone', label: 'Phone' },
    { id: 'email', label: 'Email' },
    { id: 'tags', label: 'Tags' },
  ];

  const filteredContacts = useMemo(() => {
    return contacts.filter((contact) =>
      `${contact.firstName} ${contact.lastName}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.phone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.email?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [contacts, searchTerm]);

  const rows = filteredContacts.map((contact) => ({
    id: contact.id,
    name: `${contact.firstName} ${contact.lastName}`,
    phone: contact.phone,
    email: contact.email,
    tags: (contact.tags || []).map((tag) => <><Tag variant="primary" key={tag} label={tag} />&nbsp;</>),
  }));

  const actions = [
    {
      label: 'edit',
      iconName: 'edit',
      onClick: (row) => startEditContact(row),
    },
    {
      label: 'delete',
      iconName: 'delete',
      onClick: (row) => deleteContact(row),
    },
  ];

  const loadData = useCallback(async () => {
    try {
      const response = await axiosWithCredentials.get('/fo/contacts');
      setIsLoading(false);
      setContacts(response.data);
    } catch (err) {
      console.error(err);
    }
  }, [axiosWithCredentials]);

  const startEditContact = (contact) => {
    setOpenDialog(true)
    setEditableContact(contact)
  }

  const addContact = useCallback(async (newContact) => {
    try {
      const response = await axiosWithCredentials.post('/fo/contacts', newContact);
      setContacts((prev) => [...prev, response.data]);
      setOpenDialog(false);
    } catch (err) {
      console.error(err);
    }
  }, [axiosWithCredentials]); 

  const updateContact = async (modifiedContact) => {
    try {
      const response = await axiosWithCredentials.put(`/fo/contacts/${modifiedContact.type}/${modifiedContact.id}`, modifiedContact);
      setContacts(prevContacts => {
        const updatedContacts = (prevContacts || []).map(contact =>
          contact.id === modifiedContact.id ? { ...contact, ...response.data } : contact
        );
        return updatedContacts;
      });
      setOpenDialog(false);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteContact = async (contact) => {
    try {
      await axiosWithCredentials.delete(`/fo/contacts/${contact.type}/${contact.id}`);
      setContacts(prev => prev.filter(c => c.id !== contact.id));
    } catch (err) {
      console.log(err);
    }
  };
  const SpreadLine = ({ className, children }) => (
    <div className={className} style={{ display: 'flex', justifyContent: 'space-between' }}>
      {children}
    </div>
  )

  return (
    <>
      <Card className="card-no-shadow">
        <SpreadLine>
          <h2>Contacts</h2>
          <Button type="primary" onClick={() => setOpenDialog(true)}>Add Contact</Button>
        </SpreadLine>
        {/*<SearchBar 
          placeholder="Search Contacts"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div>Showing {filteredContacts.length}  of {contacts.length}</div>*/}
        {false && isLoading && (
          <ChakraProvider>
            <Stack>
              <Skeleton height="232px" />
              <Skeleton height="232px" />
              <Skeleton height="232px" />
              <Skeleton height="232px" />
              <Skeleton height="232px" />
            </Stack>
          </ChakraProvider>
        )}
        {!isLoading && (      <AssetsTable
        isLoading={isLoading}
        columns={columns}
        rows={rows}
        actions={actions}
        displayValue={(value, column) => {
          if (column.id === 'name') {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar user={{ name: value }} />
                <span style={{ paddingLeft: 12 }}>{value}</span>
              </div>
            );
          }
          return value;
        }}
      />)
        }
        <AddContactDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          handleSubmit={editableContact ? updateContact : addContact}
          editableContact={editableContact}
        />
      </Card>
    </>
  );
}
/*          <Stack>
            {filteredContacts.map((contact) => (
                <Card className="contact-card" key={contact.id}>
                  <div style={{ display: 'flex' }}>
                    <Avatar user={{ name: `${contact.firstName} ${contact.lastName}` }} />
                    <div style={{ paddingLeft: 12 }}>
                      <div className="contact-name">{`${contact.firstName} ${contact.lastName}`}</div>
                      {contact.phone && <IconButton size="xs" aria-label={contact.phone} ><Icon name="phone" /></IconButton>}
                      {contact.email && <IconButton size="xs" aria-label={contact.email} ><Icon name="email" /></IconButton>}
                    </div>
                  </div>
                  <div className="actions">
                    <IconButton size="sm" aria-label="edit">
                      <EditIcon onClick={() => startEditContact(contact)}/>
                    </IconButton>
                    <IconButton size="sm" aria-label="delete">
                      <DeleteIcon onClick={() => deleteContact(contact)}/>
                    </IconButton>
                  </div>
              </Card>
            ))}
          </Stack>          */