import { useState, useEffect } from 'react';
import { InputLabel, Select, MenuItem, Box, Button, TextField, DialogActions, Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyIcon from '@mui/icons-material/Key';
import CloseIcon from '@mui/icons-material/Close';
import EstimateIcon from '@mui/icons-material/CloudSync'; // Import an icon for estimated value

import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
import { useSettings } from '../../context/SettingsContext';

const InfoAssetsTable = ({ onDelete, title, fields, assetTypes  , onAdd, onEdit, service, dialogTitle = "" }) => {
  const axiosWithCredentials = useAxiosWithCredentials();
  const { settings } = useSettings();

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [editingSecret, setEditingSecret] = useState(false);
  const [viewingSecret, setViewingSecret] = useState(false);
  const [secret, setSecret] = useState({});

  const [assetType, setAssetType] = useState('');
  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    try {
      const response = await axiosWithCredentials.get(`/fo/${service}/info`);
      setRows(response.data);
    } catch (error) {
      console.error("Error loading files:", error);
    }
  };

  const handleDelete = async (row) => {
    if (onDelete) {
      return onDelete(row);
    }
    try {
      const response = await axiosWithCredentials.delete(`/fo/${service}/info/${row.id}`);
      if (response.status === 200 || response.status === 201) {
        loadItems();
      } else {
        console.error('Delete failed');
      }
    } catch (error) {
      console.error('Error during Delete', error);
    }
  };

  const handleOpen = (item) => {
    if (item) {
      setFormData(item);
      setEditMode(true);
      const selectedType = item.assetType || '';
      setAssetType(selectedType);
      setCustomFields(getCustomFields(selectedType));
    } else {
      setFormData({});
      setEditMode(false);
      setAssetType('');
      setCustomFields([]);
    }
    setOpen(true);
  };

  const handleAssetTypeChange = (event) => {
    const selectedType = event.target.value;
    setAssetType(selectedType);
    setCustomFields(getCustomFields(selectedType));
    setFormData({ ...formData, assetType: selectedType });
  };

  const getCustomFields = (type) => {
    const selectedAssetType = assetTypes.find((asset) => asset.assetType === type);
    return selectedAssetType ? selectedAssetType.fields : [];
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({});
  };

  const handleSave = async () => {
    try {
      if (editMode) {
        if (onEdit) {
          return onEdit(formData);
        }
        const response = await axiosWithCredentials.put(`/fo/${service}/info/${formData.id}`, formData);
        if (response.status === 200 || response.status === 201) {
          loadItems();
        } else {
          console.error('Update failed');
        }
      } else {
        if (onAdd) {
          return onAdd(formData);
        }
        const response = await axiosWithCredentials.post(`/fo/${service}/info`, formData);
        if (response.status === 200 || response.status === 201) {
          loadItems();
        } else {
          console.error('Create failed');
        }
      }
      handleClose();
    } catch (error) {
      console.error('Error during Save', error);
    }
  };

  const handleFormChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  
  const getEstimatedValue = async (formData) => {
    const assetTypeDefinition = assetTypes.find(asset => asset.assetType === formData.assetType);
    if (assetTypeDefinition) {
      const relevantFields = {}
      assetTypeDefinition.fields.forEach((element) => relevantFields[element.id] = formData[element.id] || '');
      relevantFields.assetType = formData.assetType;
      const response = await axiosWithCredentials.post(`/assistant/estimate`, relevantFields);
      const aiData = response.data;
      setFormData({ ...formData, ...aiData });
    } else {
      // Handle error
    }
  };


  const handleOpenSecret = async (item) => {
        setFormData(item)
        console.log(formData)
        if(item.secretCreatedOn){
          const uri = `/fo/secret/${service}/${item.sortKey}`
          console.log(uri)
          const rr = await axiosWithCredentials.get(uri);
          const data = rr.data
        
          setSecret({...data, plaintext: data.ciphertextBlob.data})
          setEditingSecret(false)
        }
        else{
          //New secrets should start of ready to edit
          setEditingSecret(true)
          setSecret({})
        }
        setViewingSecret(true);
    };
    
  const saveSecret = async () => {
    if (formData.secretCreatedOn) {
        try {
          const response = await axiosWithCredentials.put(`/fo/secret/${service}/${formData.sortKey}`, secret);
          if (response.status === 200 || response.status === 201) {
            loadItems();
            console.log('Secret Update successful');
            
          } else {
            console.error('Secret Update failed');
            // Handle the upload failure (e.g., display error message)
          }
        } catch (error) {
          console.error('Error during Secret Update ', error);
          // Handle any errors that occurred during the upload process
        }
    } else {
        try {
          const response = await axiosWithCredentials.post(`/fo/secret/${service}/${formData.sortKey}`, secret);
          if (response.status === 200 || response.status === 201) {
            loadItems();
            console.log('Secret Create successful');
          } else {
            console.error('Secret Create failed');
            // Handle the upload failure (e.g., display error message)
          }
        } catch (error) {
          console.error('Error during Secret Create', error);
          // Handle any errors that occurred during the upload process
        }
    }
    setEditingSecret(false)
    setViewingSecret(false)
    };
    
    
  const viewSecret = async () => {
    try {
      const response = await axiosWithCredentials.get(`/fo/secret/${service}/${formData.sortKey}`);
      console.log(JSON.stringify(response))
      setSecret(response.data)
      
    } catch (error) {
      console.error("Error loading files:", error);
      // Handle errors appropriately
    }
    setViewingSecret(true);
  }
  
  const addSecret = () => {
    setViewingSecret(true)
  }
  const handleCloseSecret = () => {
    setViewingSecret(false)
    setEditingSecret(false)
    
  }
  const cancelEditSecret = () => {
    setViewingSecret(false)
    setEditingSecret(false)
  }
  const handleEditSecret = async () => {
    
    try {
      console.log(JSON.stringify(secret))
      const response = await axiosWithCredentials.get(`/fo/d/${secret.sortKey}`);
      console.log(JSON.stringify(response))
      
      if(response.data.plaintext){
        setSecret({...secret, plaintext: response.data.plaintext})
      }
      else{
        setSecret({...secret, plaintext: response.data.ciphertextBlob, infoMessage: "You used a private key"})
      }
      
    } catch (error) {
      console.error("Error loading files:", error);
      // Handle errors appropriately
    }
    setEditingSecret(true)
  }
  // Handle changes in the plaintext (JSON string) representation
  const handleSecretChange = (event) => {
    setSecret({ ...secret, [event.target.name]: event.target.value });
  };
  function displayValue(value, displayType) {
    switch (displayType) {
      case "masked":
        const valueStr = value.toString();
        if (valueStr.length <= 4) {
          return valueStr; // Return the value as is if it's 4 or fewer characters
        }
        return `${'*'.repeat(valueStr.length - 4)}${valueStr.slice(-4)}`;

      case "currency":
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(value);

      default:
        return value;
    }
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading policies: {error}</p>;

  return (
    <Paper>
      <Typography variant="h6" component="div" sx={{ p: 2 }}>
        {title}
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="inventory table">
          <TableHead>
            <TableRow>
              {fields.map((field) => (
                <TableCell key={field.id}>{field.label}</TableCell>
              ))}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                {fields.map((field) => (
                  <TableCell key={field.id}>{displayValue(row[field.id], field.column)}</TableCell>
                ))}
                <TableCell>
                  <IconButton aria-label="edit" onClick={() => handleOpen(row)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton aria-label="delete" onClick={() => handleDelete(row)}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton aria-label="secret" onClick={() => handleOpenSecret(row)}>
                    <KeyIcon style={{ color: row.secretCreatedOn ? 'green' : 'defaultColor' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editMode ? `Edit ${dialogTitle}` : `Add ${dialogTitle}`}</DialogTitle>
        <DialogContent>
          {!editMode && (
            <>
              <InputLabel id="asset-type-select-label">Asset Type</InputLabel>
              <Select
                labelId="asset-type-select-label"
                id="asset-type-select"
                fullWidth
                value={assetType}
                label="Asset Type"
                onChange={handleAssetTypeChange}
              >
                {assetTypes.map((asset) => (
                  <MenuItem key={asset.assetType} value={asset.assetType}>{asset.assetType}</MenuItem>
                ))}
              </Select>
            </>
          )}
          {assetType && (
            <>
              <TextField
                autoFocus
                margin="dense"
                name="description"
                label="Description"
                type="text"
                fullWidth
                value={formData.description || ''}
                onChange={handleFormChange}
              />
              {customFields.map((field) => (
                <TextField
                  autoFocus
                  margin="dense"
                  name={field.id}
                  label={field.label}
                  type="text"
                  fullWidth
                  value={formData[field.id] || ''}
                  onChange={handleFormChange}
                  key={field.id}
                />
              ))}
              <Box display="flex" alignItems="center">
                <TextField
                  margin="dense"
                  name="estimatedValue"
                  label="Estimated Value"
                  type="text"
                  fullWidth
                  value={formData.estimatedValue || ''}
                  onChange={handleFormChange}
                />
                <IconButton onClick={() => getEstimatedValue(formData)} aria-label="get estimated value">
                  <EstimateIcon />
                </IconButton>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={viewingSecret} onClose={handleCloseSecret}>
      <DialogTitle>Secret
      <IconButton
          aria-label="close"
          onClick={handleCloseSecret}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
            <Typography variant="caption" >Secrets add an additional layer of security and can be used to store things like account passwords and other sensitive information. Learn More <a>here</a></Typography>
            <Box  display="flex" flexDirection="column" alignItems="center" sx={{ borderBottom: 1, borderColor: 'divider', pt: 2}}>
              
              {/* secret.encryptionKey !== "default" && (
              <DecryptInstructions />
              )
              */}
              
              
              {settings.secrets?.useCustomKey && (
              <>
                <InputLabel id="encryption-key-select-label">Encryption Key</InputLabel>
                <Select
                  labelId="encryption-key-select-label"
                  id="encryption-key-select"
                  fullWidth
                  value={secret.encryptionKey}
                  label="Encryption Key"
                  disabled={!editingSecret}
                  name="encryptionKey"
                  onChange={handleSecretChange}
                >
                  <MenuItem value="default">Arcault Managed</MenuItem>
                  <MenuItem value={settings.secrets.keyName}>{settings.secrets.keyName}</MenuItem>
                </Select>
                </>
              )}
              
              <TextField
              sx={{mt:2}}
              label="Secret Name"
              name="name"
              fullWidth
              variant="outlined"
              disabled={!editingSecret}
              value={secret.name}
              onChange={handleSecretChange}
            />
            {editingSecret && (
              <TextField
              sx={{mt:2}}
              label="Secret"
              multiline
              name="plaintext"
              fullWidth
              variant="outlined"
              value={secret.plaintext}
              onChange={handleSecretChange}
              rows={4}
            />
            )}
            {!editingSecret && (
              <Box>
              <Button onClick={handleEditSecret} sx={{mt:2 }} variant="contained" color="secondary" component="label">
                Retrieve Secret
              </Button>
              </Box>
              )}
            </Box>
            <DialogActions>
              {editingSecret && (
              <>
              <Button onClick={cancelEditSecret} sx={{mt:2 }} variant="contained" color="secondary" component="label">
                Cancel
              </Button>
              <Button onClick={saveSecret} sx={{mt:2 }} variant="contained" color="secondary" component="label">
                Save Secret
              </Button>
              </>
              )}
            </DialogActions>
      </DialogContent>
    </Dialog>
      <IconButton aria-label="add" color="primary" onClick={() => handleOpen()}>
        <AddCircleOutlineIcon />
      </IconButton>
    </Paper>
  );
};

export default InfoAssetsTable;
