import { useNavigate } from 'react-router-dom';

import { Card, HStack, Text } from '@arcault-ui/shared-components'
import InitialForm from './InitialForm'
import { useSettings } from '../../context/SettingsContext';

import EstateNextStep from '../estate/EstateNextStep'
import InsuranceNextStep from '../insurance/InsuranceNextStep'


const Home = (props) => {
  const {settings = {} , updateSettings } = useSettings();
  const {basicInfo = {} , updateBasicInfo } = useSettings();

  return (
    <>
      {(settings?.views?.splashScreenIndex && settings?.views?.splashScreenIndex !== 'finish') && <InitialForm
        isOpen={(settings?.views?.splashScreenIndex && settings?.views?.splashScreenIndex !== 'finish')}
        basicInfo={basicInfo}
        updateBasicInfo={updateBasicInfo}
        settings={settings}
        updateSettings={updateSettings}
      />}
      <Card className="overview-inner">
        <div style={{ paddingBottom: 32 }}>
          <Text variant="h2">Welcome, Matt</Text>
          <Text variant="h3">How do you want to build your legacy this morning?</Text>
        </div>

        <HStack className="overview-content">
          <EstateNextStep dashboard priority />
          <InsuranceNextStep dashboard />
        </HStack>
      </Card>
    </>
  );
}

export default Home;