import React from 'react';
import { Box, Card, CardActions, CardContent, Button, Typography, Grid } from '@mui/material';

const SuggestedNextStep = ({ suggestions }) => {
  
  return (
    <Grid container spacing={2} sx={ {pt: 2} }>
      {suggestions.map((suggestion, index) => (
        <Grid item key={index} xs={12} md={6} lg={4}>
          <Card variant="outlined" sx={{ height: 250 }}>
          <Box display="flex" flexDirection="column" height="100%">
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h5" component="div">
                {suggestion.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {suggestion.description}
              </Typography>
            </CardContent>
            
            {suggestion.action && (
              <CardActions>
                <Button size="small" onClick={() => suggestion.action.onClick()}>
                  {suggestion.action.label}
                </Button>
              </CardActions>
            )}
            
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default SuggestedNextStep;
