import React, { useState, useEffect } from 'react';
import { Card, Checkbox, Button, FormInput, HStack, Modal, Text, Stack } from '@arcault-ui/shared-components';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
import { useNavigate } from 'react-router-dom';

import { useHelp } from '../../context/HelpContext';
import DependantInfo from '../common/DependantInfo';

const userJourneyTemp = {
  journey: {
    journey: {
      currentStep: {
        name: "childPhone",
        ask: "Do you want $1000 in free life insurance?",
        label: "Click to confirm that you want this gift from Arcault.",
        tip: "Life insurance is a gift from Arcault to you and your family, no strings attached. We believe this gives a critical minimal level of coverage to all our valued users. ",
        type: "checkbox",
        page: '/insurance',
        pageName: 'All Dependents',
      }
    }
  }
}

const FormRenderer = ({ formData, isOpen, setIsOpen }) => {
  const { form, props } = formData;

  const formComponents = {
    'add-dependent': (
      <Modal title="Add Dependent" isOpen={isOpen}>
        <DependantInfo {...props} />
        <HStack variant="end">
          <Button type="primary" onClick={() => setIsOpen(false)}>Done</Button>
        </HStack>
      </Modal>
     )
  };

  return (
    <>
      {formComponents[form] || <></>}
    </>
  );
};


const InsuranceNextStep = ({ dashboard, priority }) => {
    const [onActionClick, setOnActionClick] = useState(() => {})
    const [nextStep, setNextStep] = useState(userJourneyTemp.journey.journey.currentStep);
    const [stepData, setStepData] = useState("");
    const [formOpen, setFormOpen] = useState(false);
    
    const axiosWithCredentials = useAxiosWithCredentials();
    const navigate = useNavigate();

    const { setHelpPrompt } = useHelp();
    
      useEffect(() => {
        const getUserJourney = async () => {
          try {
            const userJourney = await axiosWithCredentials.get('/ajourney/journey/next/estate_planning');
            setNextStep(userJourney?.data?.userJourney?.journey?.journey?.currentStep);
            setOnActionClick(() => {});
            // setForm(FORM_MAP[userJourney?.data?.userJourney?.journey?.journey?.currentStep?.form]?.form);
          } catch (error) {
            console.error("Failed to load user journey:", error);
          }
        }
        getUserJourney();
      }, []);

      const handleInputChange = (e) => {
        const value = e.target.value;
        setStepData(value);
      }

      const handleCheckedChange = (e) => {
        console.log('handleCheckedChange', e.target.checked, e.target);
        const value = e.target.checked;
        setStepData(value);
      }
        
      return (
        <Card className={`estate-next-step ${dashboard ? priority ? 'priority-card-dashbord' : 'dashboard-card' : 'card-mint-highlight'}`} title={dashboard ? 'Life Insurance ' : 'Next Step'} footer={(
          <HStack variant={nextStep.form || stepData ? 'spread' : 'end'}>
            {dashboard && <Button type="outline" onClick={() => navigate('/estate')}>Dashboard</Button>}
            {!dashboard && nextStep.page && <Button type="outline" onClick={() => navigate(nextStep.page)}>{nextStep.pageName}</Button>}
            {!nextStep.page && (nextStep.form || stepData) && <>&nbsp;</>}
            {(nextStep.form || stepData) && 
              <Button type="primary" onClick={() => setFormOpen(true)}>{stepData ? 'Save' : 'Open Form'}</Button>
            }
          </HStack>
        )}>
        {nextStep && <FormRenderer formData={nextStep} isOpen={formOpen} setIsOpen={setFormOpen}/>}
        {nextStep && (
           <Stack variant="center" className="estate-next-step-inner">
                {(nextStep.type === "text") && (
                  <FormInput autoFocus data-testid="arcault-input" label={nextStep.ask} value={stepData} type={nextStep.type} onChange={handleInputChange}/>
                )}
                {(nextStep.type === "checkbox") && (
                  <div style={{ paddingBottom: 16 }}>
                    <Text variant="subheading">{nextStep.ask}</Text>
                    <Checkbox label={nextStep.label} value={stepData} type={nextStep.type} onChange={handleCheckedChange}/>
                  </div>
                )}
                  <Text variant="body">{nextStep.tip} <span className="help-link" onClick={() => setHelpPrompt(nextStep.tip)}>Questions?</span></Text>
            </Stack>
          )}
        </Card>
      );
    };
    
    export default InsuranceNextStep;
