import React, { useState, useEffect } from 'react';
import { Card, Button, FormInput, HStack, Modal, Text, Stack } from '@arcault-ui/shared-components';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
import { useNavigate } from 'react-router-dom';

import { useHelp } from '../../context/HelpContext';
import DependantInfo from '../common/DependantInfo';

/*
{
  name: "childPhone",
  ask: "What is your child's name?",
  tip: "Please provide a contact number for your child. This will help us reach them in case of an emergency.",
  validation: {
    regex: /^[0-9]{10}$/,
    message: "Please enter a valid 10-digit phone number."
  },
  type: "text",
  form: 'add-dependent',
  props: { forceStep: 2 },
  page: '/profile/family',
  pageName: 'All Dependents',
}*/

const steps = [{
  name: "childName",
  ask: "What is your child's name?",
  tip: "Please provide the full name of your child. Any format will do.",
  validation: {
    regex: /^[a-zA-Z\s]+$/,
    message: "Please enter a valid name."
  },
  type: "text",
  form: 'add-dependent',
  props: { forceStep: 1 },
  page: '/profile/family',
  pageName: 'All Dependents',
},{
  name: "childPhone",
  ask: "What is your child's phone number?",
  tip: "Please provide a contact number for your child. This will help us reach them in case of an emergency.",
  validation: {
    regex: /^[0-9]{10}$/,
    message: "Please enter a valid 10-digit phone number."
  },
  type: "text",
  form: 'add-dependent',
  props: { forceStep: 2 },
  page: '/profile/family',
  pageName: 'All Dependents',
},{
  name: "childEmail",
  ask: "What is your child's email address?",
  tip: "Please provide an email address for your child. This will help us send important information.",
  validation: {
    regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    message: "Please enter a valid email address."
  },
  type: "text",
  form: 'add-dependent',
  props: { forceStep: 3 },
  page: '/profile/family',
  pageName: 'All Dependents',
}]

const userJourneyTemp = {
  journey: {
    journey: {
      currentStep: steps[0]
    }
  }
}

const FormRenderer = ({ formData, isOpen, setIsOpen }) => {
  const { form, props } = formData;

  const formComponents = {
    'add-dependent': (
      <Modal title="Add Dependent" isOpen={isOpen}>
        <DependantInfo {...props} />
        <HStack variant="end">
          <Button type="primary" onClick={() => setIsOpen(false)}>Done</Button>
        </HStack>
      </Modal>
     )
  };

  return (
    <>
      {formComponents[form] || <></>}
    </>
  );
};


const EstateNextStep = ({ dashboard, priority }) => {
    const [onActionClick, setOnActionClick] = useState(() => {})
    const [nextStep, setNextStep] = useState(userJourneyTemp.journey.journey.currentStep);
    const [stepData, setStepData] = useState("");
    const [formOpen, setFormOpen] = useState(false);
    const [stepIndex, setStepIndex] = useState(0);
    
    const axiosWithCredentials = useAxiosWithCredentials();
    const navigate = useNavigate();

    const { setHelpPrompt } = useHelp();
    
      useEffect(() => {
        const getUserJourney = async () => {
          try {
            const userJourney = await axiosWithCredentials.get('/ajourney/journey/next/estate_planning');
            setNextStep(userJourney?.data?.userJourney?.journey?.journey?.currentStep);
            setOnActionClick(() => {});
            // setForm(FORM_MAP[userJourney?.data?.userJourney?.journey?.journey?.currentStep?.form]?.form);
          } catch (error) {
            console.error("Failed to load user journey:", error);
          }
        }
        getUserJourney();
      }, []);

      const handleInputChange = (e) => {
        const value = e.target.value;
        setStepData(value);
      }
        
      const saveData = () => {
        const nextStepIndex = steps.length === stepIndex + 1 ? 0 : stepIndex + 1 
        setNextStep(steps[nextStepIndex]);
        setStepIndex(nextStepIndex);
        setStepData('');

        // Implement your save data logic here
        console.log("Data saved:", stepData);
      };

      const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          saveData();
        }
      };

      return (
        <Card className={`estate-next-step ${dashboard ? priority ? 'priority-card-dashbord' : 'dashboard-card' : 'card-mint-highlight'}`} title={dashboard ? 'Estate Planning' : 'Next Step'} footer={(
          <HStack variant="spread">
        {dashboard && <Button type="outline" onClick={() => navigate('/estate')}>Dashboard</Button>}
        {!dashboard && nextStep.page && <Button type="outline" onClick={() => navigate(nextStep.page)}>{nextStep.pageName}</Button>}
        {!nextStep.page && <>&nbsp;</>}
        <Button type="primary" onClick={() => setFormOpen(true)}>{stepData ? 'Save' : 'Open Form'}</Button>
          </HStack>
        )}>
        {nextStep && <FormRenderer formData={nextStep} isOpen={formOpen} setIsOpen={setFormOpen}/>}
        {nextStep && (
           <Stack variant="center" className="estate-next-step-inner">
            {(nextStep.type === "text") && (
          <>
            <FormInput autoFocus data-testid="arcault-input" label={nextStep.ask} value={stepData} type={nextStep.type} onChange={handleInputChange} onKeyDown={handleKeyPress}/>
            {stepData && <div className="direction-text">Enter to save.</div>}
          </>
            )}
            <Text variant="body">{nextStep.tip} <span className="help-link" onClick={() => setHelpPrompt(nextStep.tip)}>Questions?</span></Text>
        </Stack>
          )}
        </Card>
      );
    };
    
    export default EstateNextStep;
