import React, { useEffect, useState } from 'react';

import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
import { Card, Text, Button, HStack, Stack } from '@arcault-ui/shared-components';
import './EstateValue.css'; // Assuming you have a CSS file for additional styles

const EstateValueCard = ({ navigate }) => {
    const [assets, setAssets] = useState([]);
    const [liabilities, setLiabilities] = useState([]);
    const [estateValue, setEstateValue] = useState(null);
    const [largestAssets, setLargestAssets] = useState([]);
    const [largestLiabilities, setLargestLiabilities] = useState([]);

    const formattedValue = (value) => {
        if (value >= 1000000) {
            return `$${(value / 1000000).toFixed(1)} million`;
        } else if (value >= 10000) {
            return `$${(value / 1000).toFixed(1)}k`;
        } else {
            return `$${value}`;
        }
    };
    const hasValue = Boolean(estateValue);

  const axiosWithCredentials = useAxiosWithCredentials();


useEffect(() => {
    const fetchAssets = async () => {
      try {
        const response = await axiosWithCredentials.get('/fo/assets/info');
        setAssets(response.data);
      } catch (error) {
        console.error("Failed to load contacts:", error);
      }
    };


    const fetchLiabilities = async () => {
        try {
          const response = await axiosWithCredentials.get('/fo/liabilities/info');
          setLiabilities(response.data);
        } catch (error) {
          console.error("Failed to load contacts:", error);
        }
      };

      fetchLiabilities();
      fetchAssets();
  }, [axiosWithCredentials]);

  useEffect(() => {
    if (assets && liabilities) {
        let totalAssets = 0;
        assets.forEach(asset => {
            totalAssets += parseInt(asset.estimatedValue);
        });
        const largestAssetsList = assets
            .sort((a, b) => b.estimatedValue - a.estimatedValue)
            .slice(0, 3);

        const totalLiabilities = liabilities.reduce((sum, liability) => sum + liability.estimatedValue, 0);
        const largestLiabilitiesList = liabilities
            .sort((a, b) => b.estimatedValue - a.estimatedValue)
            .slice(0, 3);

        const estimatedValue = totalAssets - totalLiabilities;
        
        
        setEstateValue(estimatedValue);
        setLargestAssets(largestAssetsList);
        setLargestLiabilities(largestLiabilitiesList);
    }
  }, [assets, liabilities]);

  return (
    <Card className="estate-value-card your-vault-card" footer={(
        <HStack variant="end">
            <Button type="outline" onClick={() => navigate('/profile/assets')}>View all</Button>
        </HStack>
    )}>
        <Stack variant="center">
            <div>
                <Text variant="h2">Estate Value</Text>
                <Text variant="h1" className={`value ${hasValue ? 'has-value' : 'no-value'}`}>
                    <span data-testid="estate-value">{formattedValue(estateValue)}</span>
                </Text>
                {hasValue && (
                    <div className="description">
                        <Text variant="body">
                            Based on <span data-testid="assets-total">{assets.length}</span> assets and <span data-testid="liabilities-total">{liabilities.length}</span> liabilities, including your {' '}
                            {largestAssets.map((asset, index) => (
                                <span key={index} className="asset">
                                {asset.assetType}

                                {index < largestAssets.slice(0, 3).length - 1 ? ', ' : ''}
                                {index === largestAssets.length - 2 && ' and '}
                                </span>
                            ))}
                            {liabilities.length > 0 && `, and `}
                            {largestLiabilities.slice(0, 3).map((liability, index) => (
                                <span key={index} className="liability">
                                {liability.assetType}
                                {index < largestLiabilities.slice(0, 3).length - 1 ? ', ' : ''}
                                {index === largestLiabilities.length - 2 && ' and '}
                                </span>
                            ))}
                            .
                        </Text>
                    </div>
                )}
            </div>
        </Stack>
    </Card>
  );
};

export default EstateValueCard;