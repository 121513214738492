import {useState, useEffect}  from 'react';

import { Modal, Button, Text } from '@arcault-ui/shared-components';
import { Spinner } from '@chakra-ui/react';

import BasicInfo from '../common/BasicInfo';
import RelationshipInfo from '../common/RelationshipInfo'
import DependantInfo from '../common/DependantInfo'
import SpreadLine from '../common/SpreadLine'

import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';

import './InitialForm.css'

const addRelationshipLabel = 'Add Relationship'
const addDependantsLabel = 'Add Dependants'
const finishLabel = 'Finish'

const editBasicLabel = 'Edit Basic Info'
const requiredBasicInfo = ['firstName', 'lastName', 'email', 'address', 'birthdate']
const requiredPartnerInfo = ['partnerFirstName', 'partnerLastName', 'partnerEmail', 'partnerPhone', 'partnerDateOfBirth']

// Statuses that preclude the partner info step
const noPartnerInformationStatuses = [
  'Single', 'Divorced', 'Widowed'
]

const InitialForm = ({ forceStep, isOpen, basicInfo, updateBasicInfo, settings, updateSettings }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);  
  const [formData, setFormData] = useState({ hasDependants: true });
  const [partnerData, setPartnerData] = useState({});
  const [dependantData, setDependantData] = useState([]);

  const axiosWithCredentials = useAxiosWithCredentials();

  useEffect(() => {
    // axiosWithCredentials.delete('/fo/contacts/family_partner/' + 'bdd2d4bc-60d0-4238-b032-8838091a5cf4');

    const getPartnerData = async () => {
      const partnerResponse = await axiosWithCredentials.get('/fo/contacts/family_partner', partnerData);
      if (partnerResponse?.data[0]) {
        setPartnerData(prevFormData => ({ ...prevFormData, ...partnerResponse.data[0] }));
      }
    }

    getPartnerData()
  }, [])

  /* Controlling isLoading */
  useEffect(() => {
    const currentStep = parseInt(forceStep) || settings?.views?.splashScreenIndex;
    console.log('settings', settings, currentStep)
    switch (currentStep) {
      case 0:
        if (basicInfo) setIsLoading(false);
        break;
      case 1:
        if (partnerData) setIsLoading(false);
        break;
      case 2:
        if (dependantData) setIsLoading(false);
        break;
      default:
        break;
    }
  }, [basicInfo, partnerData, dependantData])

  const formSteps = [
    {
      title: 'Basic Information',
      Component: BasicInfo,
      active: true,
      formData,
      setFormData,
      next: ({ relationship, hasDependants }) => {
        let label = addRelationshipLabel;
        const step = 1;
        if (noPartnerInformationStatuses.includes(relationship)) {
          label = addDependantsLabel
          if (hasDependants === 'false') {
            label = finishLabel
          } 
        } 
        
        return ({
          label,
          step,
        })
      },
      previous: () => ({ label: 'NA', step: 0 }),
      valid: (formData) => {
        let allValid = true;
        requiredBasicInfo.forEach(i => {
          if (!formData[i]) allValid = false;
        })
        
        return allValid;
      }
    },
    {
      title: 'Relationship Info',
      active: ({ relationship }) => !noPartnerInformationStatuses.includes(relationship),
      Component: RelationshipInfo,
      formData: partnerData,
      setFormData: setPartnerData,
      next: ({ hasDependants }) => {
        let label = addDependantsLabel;
        const step = 2;
        if (hasDependants === 'false') {
          label = finishLabel
        } 
  
        return ({
          label,
          step
        })
      },
      previous: () => ({ label: editBasicLabel, step: 0 }),
      valid: (formData) => {
        let allValid = true;
        requiredPartnerInfo.forEach(i => {
          if (!formData[i]) {
            console.log('invalid', i)
            allValid = false;
          }
        })
        
        return allValid;
      }
    },
    {
      title: 'Dependants',
      Component: DependantInfo,
      formData: dependantData,
      setFormData: setDependantData,
      next: () => ({
        step: 'finish',
        label: finishLabel
      }),
      previous: () => ({
        step: 1 ,
        label: 'Edit Relationship'
      }),
      valid: () => true,
    }
  ];

  useEffect(() => {
    setFormData(basicInfo)

    console.log('settings', settings)

    if (forceStep || settings?.views) {
      setActiveStep(forceStep || settings.views.splashScreenIndex);
    }

  }, [basicInfo, settings])


  const updateSettingsStep = async ({ dataComplete, nextStep }) => {
    const newSettings = {
      ...settings,
      views: {
        ...settings.views,
        showSplashScreen: !dataComplete,
        splashScreenIndex: nextStep,
      }
    };
    updateSettings(newSettings);

  }
  
  const handleInput = (event) => {
    const { name, value } = event.target;

    const setData = formSteps[activeStep].setFormData

    setData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };
  
  const handleNext = async () => {
    switch (activeStep) {
      case 0:
        updateBasicInfo(formData)
        break;
      case 1:
        if(partnerData.id){ 
          const partnerResponse = await axiosWithCredentials.put(`/fo/contacts/family_partner/${partnerData.id}`, partnerData);
          setPartnerData(prevFormData => ({ ...prevFormData, ...partnerResponse.data }));
        }
        else{
          const partnerResponse = await axiosWithCredentials.post('/fo/contacts/family_partner', partnerData);
          setPartnerData(prevFormData => ({ ...prevFormData, ...partnerResponse.data }));
        }
        break
      case 2:
        try {
          const updatePromises = dependantData.map(dependant => {
              return axiosWithCredentials.post(`/fo/contacts/family_children`, dependant)
          });
          const responses = await Promise.all(updatePromises);
          console.log('All dependants updated successfully:', responses);
          // Update UI or state to reflect successful updates if necessary
        } catch (error) {
            console.error('Error updating dependants:', error);
            // Handle errors or update UI to reflect that an error occurred
        }
      break;
      default:
        break;
    }
        
    const nextStep = next.step
    updateSettingsStep({ dataComplete: false, nextStep })
    setActiveStep(nextStep)
  }


const step = activeStep ? formSteps[activeStep] : {}
const ActiveStepComponent = step.Component
const title = step.title
const data = step.formData
const setData = step.setFormData

const next = step.next && step.next(formData)
const previous = step.previous && step.previous(formData)
const valid = step.valid && step.valid(formData)

const Footer = (
  <SpreadLine>
    {activeStep === 0 && <div>&nbsp;</div>}
    {activeStep !== 0 && <Button type="outline" onClick={() => setActiveStep(previous.step)}>
      {previous?.label}
    </Button>}
    <Button isDisabled={!valid} type="primary" onClick={handleNext}>
      {next?.label}
    </Button>
  </SpreadLine>
)

return (
  <Modal isOpen={isOpen} title={title} footer={Footer} >
      {isLoading && <div className="loading-form">
        <div><Text variant="h4">Loading...</Text></div>
        <Spinner />
      </div>}
      {!isLoading && <ActiveStepComponent formData={data} setFormData={setData} handleInputChange={handleInput}/>}
  </Modal>
)}
    
export default InitialForm;