import React, { useState, useEffect } from 'react';
import './ProposalInbox.css';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';

import { 
  Avatar, 
  Button, 
  Card, 
  HStack,
  ListItem,
  Modal,
  Stack,
  Text,
} from '@arcault-ui/shared-components';

const allProposals = [
  { 
    id: 0,
    companyName: 'RPG Legal Services', 
    estCost: 1500, 
    notesOnCost: 'This estimate is based on a preliminary assessment and may change depending on the complexity of your estate.',
    estTime: '3 weeks',
    notesOnTime: 'The estimated time frame is subject to change based on the specifics of your case and our current workload.',
    message: 'At RPG Legal Services, we specialize in comprehensive estate planning. Reach out to us to secure your future.',
    avatarUrl: 'https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/3Hf385Ky9vXtqxqJqKRz/media/66c65fa4ee2a8d8fa57abd4d.jpeg',
  },
  { 
    id: 1,
    companyName: 'AB&C Law Firm', 
    estCost: 2000, 
    notesOnCost: 'This is a ballpark figure and can vary based on the specific requirements of your estate planning.',
    estTime: '4 weeks',
    notesOnTime: 'The duration is an estimate and may be adjusted according to the details of your estate and our schedule.',
    message: 'AB&C Law Firm offers personalized estate planning services. Contact us to tailor a plan that fits your needs.',
    avatarUrl: 'https://ggbpc.com/wp-content/uploads/2024/01/e-spencer-ghazey-bates-headshot-oeaprhh9qubk7j75hhmbi6yevod9nxubpbdz0gsf0g.jpg',
  },
  { 
    id: 2,
    companyName: 'Smith & Jones Attorneys', 
    estCost: 1800, 
    notesOnCost: 'The provided estimate is subject to change based on the intricacies of your estate planning needs.',
    estTime: '3-4 weeks',
    notesOnTime: 'The estimated time frame may vary depending on the complexity of your case and our current caseload.',
    message: 'Smith & Jones Attorneys are here to assist you with all your estate planning needs. Get in touch for a detailed consultation.',
    avatarUrl: 'https://justatic.com/profile-images/1334015-1444792731-sl.jpg',
  },
];

// For testing purposes
const NUM_PROPOSAL_TO_SHOW = 3;
const SHOW_DUMMY_PROPOSALS = true;
const filteredProposals = allProposals.slice(0, NUM_PROPOSAL_TO_SHOW); 

const PROPOSALS_ENDPOINT = '/fo/estate/proposals';

const ProposalInbox = () => {
  const [compareModalOpen, setCompareModalOpen] = useState(false);
  const axiosWithCredentials = useAxiosWithCredentials();
  const [proposals, setProposals] = useState([]);

  const getProposals = async () => {
    const proposalsResponse = await axiosWithCredentials.get(PROPOSALS_ENDPOINT); 
    setProposals(SHOW_DUMMY_PROPOSALS ? filteredProposals : proposalsResponse.data);
  }

  useEffect(() => {
   getProposals();
  }, [])

  const acceptProposal = (proposal) => {
    // Implement your logic here to accept the proposal
    setCompareModalOpen(false);
  }

  return (
    <>
      <Modal key="compare-modal" className="compare-proposals" isOpen={compareModalOpen} title="Compare Proposals">
        <HStack>
        {proposals.map((proposal) => (
          <Card key={`proposal-compare-${proposal.id}`}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Avatar user={{ avatarUrl: proposal.avatarUrl }} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Text variant="h3">{proposal.companyName}</Text>
            </div>
            <div className="compare-section" >
              <Text variant="body">{proposal.message}</Text>
            </div>
            <div className="compare-section" >
              <Text variant="subheading">Estimated Cost: ${proposal.estCost}</Text>
              <Text variant="body">{proposal.notesOnCost}</Text>
            </div>
            <div className="compare-section" >
              <Text variant="subheading">Estimated Time: {proposal.estTime}</Text>
              <Text variant="body">{proposal.notesOnTime}</Text>
            </div>
            <div style={{padding: '32px 0px 0px', textAlign: 'right'}} >
              <Button type="primary" onClick={() => acceptProposal(proposal)}>Accept</Button>
            </div>
          </Card>
        ))}
        </HStack>
      </Modal>
      <Card key="proposal-list" className={`proposal-inbox proposal-inbox-${proposals.length === 3 ? 'complete' : 'incomplete'}`}>
        <div key="header" className="card-header" style={{ display: 'flex'}}>
          <div>Proposals</div>
          {proposals.length > 1 && <Button type="ghost" onClick={() => setCompareModalOpen(true)}>Compare</Button>}
        </div>
        {proposals && proposals.length > 0 && (
          <Stack>
            {proposals.map((proposal) => (
              <ListItem key={`proposal-${proposal.id}`}>
                <HStack>
                  <Avatar user={{ avatarUrl: proposal.avatarUrl }} />
                  <Stack gap="0" className="proposal-card-info-stack">
                    <Text variant="subheading">{proposal.companyName}</Text>
                    <Text variant="caption">
                      {proposal.message.length > 120 ? `${proposal.message.substring(0, 117)}...` : proposal.message}
                    </Text>
                  </Stack>
                </HStack>
              </ListItem>
            ))}
          </Stack>
        )}
        {proposals.length === 0 && (
          <div data-testid="help-section-none" className="help-section">
              You don't have any proposals yet.  We need a little more information to connect you with estate planning attorneys. Check your progress to the left.   
          </div>
        )}
        {proposals.length < 3 && proposals.length > 0 && (
          <div data-testid="help-section-wait" className="help-section">
            {`Congratulations, you've received ${proposals.length === 1 ? 'a proposal' : 'proposals'}!`} <b>We recommend you wait a little longer.</b> 
            {proposals.length === 1 && ` Additional estate planning attorneys have your request in their inbox, and we want you to receive the full benefits of comparison.`}</div>
        )}
      </Card>
    </>
  )
}

export default ProposalInbox;